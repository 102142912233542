
import { defineComponent } from "vue";
import DisplacementCatalogue from "@/components/catalogues/DisplacementCatalogue/DisplacementCatalogue.vue";

export default defineComponent({
  components: {
    DisplacementCatalogue,
  },
  props: {},
  methods: {},
  computed: {},
  data() {
    return {};
  },
});
